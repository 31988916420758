import React, {Fragment} from "react";
import {
  IInventoryItem
} from "../../../common/interfaces/inventory-item.interface";
import {
  Button,
  Card,
  CardActionArea, CardActions,
  CardContent,
  CardMedia, Chip,
  Typography
} from "@mui/material";

interface InventoryItemCardProps {
  item: IInventoryItem
}

const InventoryItemCard = ({item}: InventoryItemCardProps): JSX.Element => {

  return (
    <Card>
      <CardActionArea>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {item.name} - ${item.price}
          </Typography>
          {item.imageUrls.map((img: string) =>
            <Fragment key={img}>
              <CardMedia
                component="img"
                style={{maxHeight: 125}}
                image={img}
              />
            </Fragment>
          )}
          <Typography variant="body2" color="text.secondary">
            {item.desc}
          </Typography>
          <Chip label={`${item.shippingLeadDays} Day Shipping`} />
          <Chip label={item.id} />
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="primary">
          Add to Order
        </Button>
      </CardActions>
    </Card>
  );
};

export default InventoryItemCard;
