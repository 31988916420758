import {
  Container,
  Grid,
  FormControl, InputLabel, OutlinedInput, InputAdornment, Divider
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import React from "react";
import {IInventoryItem} from "../../common/interfaces/inventory-item.interface";
import {getTempInventoryUtil} from "./get-temp-inventory.util";
import InventoryItemCard from "./components/InventoryItemCard";

const InternalOrderingPage = () => {
  const [filteredInventory, setFilteredInventory] = React.useState<IInventoryItem[]>([])
  const [inventoryItems, setInventoryItems] = React.useState<IInventoryItem[]>([])
  const [textFilter, setTextFilter] = React.useState("")

  // get inventory
  React.useEffect(() => {
    if (inventoryItems.length === 0) {
      console.log(`fetching inventory...`)
      setInventoryItems(getTempInventoryUtil())
    }
  }, [inventoryItems])

  // filter inventory
  React.useEffect(() => {
    const asyncFunc = async () => {
      if (textFilter === "") {
        await setFilteredInventory(inventoryItems)
      } else {
        let filteredItems: IInventoryItem[] = []
        for (const i of inventoryItems) {
          if (i.name.toLowerCase().includes(textFilter.toLowerCase())) {
            filteredItems.push(i)
          }
        }
        await setFilteredInventory(filteredItems)
      }
    }
    asyncFunc().then(() => {})
  }, [textFilter, inventoryItems])


  const changeFilterValue = async (e: React.ChangeEvent<HTMLInputElement>) => {
    await setTextFilter(e.currentTarget.value)
  }

  return (
    <>
      <Container>
        <h2>KTW Internal Ordering</h2>
        <Divider />
        <Grid container spacing={1}>
          <Grid item xs={11} sm={6}>
            <FormControl fullWidth sx={{ m: 1 }}>
              <InputLabel htmlFor="outlined-adornment-amount">
                Search
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-amount"
                startAdornment={<InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>}
                label="Search"
                size="small"
                value={textFilter}
                onChange={changeFilterValue}
              />
            </FormControl>
            {filteredInventory.length} items
            {filteredInventory.map((item: IInventoryItem) =>
              <div className="Row" key={item.id} style={{paddingBottom: 5, paddingTop: 5}}>
                <InventoryItemCard
                  item={item}
                />
              </div>
            )}
          </Grid>
          <Grid item xs={1}>
            &nbsp;
          </Grid>
          <Grid item xs={11} sm={5}>
            <h3>Current Order</h3>
          </Grid>
          <Grid item xs={1}>
            &nbsp;
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default InternalOrderingPage;
