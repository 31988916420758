import {IInventoryItem} from "../../common/interfaces/inventory-item.interface";

export function getTempInventoryUtil(): IInventoryItem[] {
  return [
    {
      id: "001",
      name: "265/65/17 - Michelin",
      desc: "40,000 Mile Tire",
      shippingLeadDays: 2,
      price: 100,
      shippingPrice: 5,
      imageUrls: [
        "https://images.simpletire.com/images/q_auto/f_auto,q_auto,fl_lossy,h_828/line-images/1755/1755-sidetread/michelin-ltx-a-t2.png"
      ],
      qtyInKtwStock: 0
    },
    {
      id: "002",
      name: "265/65/17 - GoodYear",
      desc: "50,000 Mile Tire",
      shippingLeadDays: 1,
      price: 100,
      shippingPrice: 5,
      imageUrls: [
        "https://i5.walmartimages.com/asr/97e6df10-5771-4701-a040-6b6b015b3773.400874d938bd6a36e3391979ef30825f.jpeg?odnHeight=2000&odnWidth=2000&odnBg=FFFFFF"
      ],
      qtyInKtwStock: 0
    },
    {
      id: "51700",
      name: "FRONT RUNNER GUARDS",
      desc: "15-20 | Ford Transit Van",
      shippingLeadDays: 3,
      price: 571,
      shippingPrice: 5,
      imageUrls: [
        "https://temp-images-public-dev.s3.amazonaws.com/inventory-images/51700.jpeg"
      ],
      qtyInKtwStock: 0
    },
    {
      id: "402230-1",
      name: "4inch STRAIGHT SIDEBARS (NEW STEP PAD)",
      desc: "07-18 | Chevy Silverado / GMC Sierra | Crew Cab",
      shippingLeadDays: 1,
      price: 346,
      shippingPrice: 5,
      imageUrls: [
        "https://temp-images-public-dev.s3.amazonaws.com/inventory-images/402230P.jpg"
      ],
      qtyInKtwStock: 5
    },
    {
      id: "70010B",
      name: "3inch BULL BARS",
      desc: "99-07 | Chevy Silverado, GMC Sierra 1500 (07 Classic) / 00-06 Chevy Suburban 1500, GMC Yukon XL 1500 / 00-06 Chevy Tahoe, GMC Yukon 1500 / 02-06 Chevy Avalanche 1500 | (07 Classic)",
      shippingLeadDays: 1,
      price: 337,
      shippingPrice: 5,
      imageUrls: [
        "https://temp-images-public-dev.s3.amazonaws.com/inventory-images/bullbar-stock-black-side.jpg"
      ],
      qtyInKtwStock: 2
    }
  ]
}
