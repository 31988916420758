import { useState, MouseEvent } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Container,
  Avatar,
  Button,
  Tooltip,
} from "@mui/material";
import {
  Menu as MenuIcon,
  CarRepair,
  AccountCircle,
} from "@mui/icons-material";

import { useCognitoUserInfo } from "../hooks";

const NavBar = () => {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const cognitoUserinfo = useCognitoUserInfo();

  const handleOpenNavMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar
      position="static"
      sx={{
        position: "relative",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <CarRepair
            sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}
            onClick={() => navigate("/")}
          />
          <Typography
            variant="h6"
            noWrap
            component="a"
            onClick={() => navigate("/")}
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            {process.env.REACT_APP_NAME}
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuItem onClick={() => navigate("/conversations")}>
                <Typography textAlign="center">Conversations</Typography>
              </MenuItem>
              <MenuItem onClick={() => navigate("/contacts")}>
                <Typography textAlign="center">Contacts</Typography>
              </MenuItem>
              <MenuItem onClick={() => navigate("/internal-ordering")}>
                <Typography textAlign="center">Internal Ordering</Typography>
              </MenuItem>
              <MenuItem onClick={() => navigate("/marketplace-orders")}>
                <Typography textAlign="center">Marketplace Orders</Typography>
              </MenuItem>
              <MenuItem onClick={() => navigate("/workflow-steps")}>
                <Typography textAlign="center">Workflow Steps</Typography>
              </MenuItem>
            </Menu>
          </Box>
          <CarRepair
            sx={{ display: { xs: "flex", md: "none" }, mr: 1 }}
            onClick={() => navigate("/")}
          />
          <Typography
            variant="h5"
            noWrap
            component="a"
            onClick={() => navigate("/")}
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            {process.env.REACT_APP_NAME}
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            <Button
              onClick={() => navigate("/conversations")}
              sx={{ my: 2, color: "white", display: "block" }}
            >
              Conversations
            </Button>
            <Button
              onClick={() => navigate("/contacts")}
              sx={{ my: 2, color: "white", display: "block" }}
            >
              Contacts
            </Button>
            <Button
              onClick={() => navigate("/internal-ordering")}
              sx={{ my: 2, color: "white", display: "block" }}
            >
              Internal Ordering
            </Button>
            <Button
              onClick={() => navigate("/marketplace-orders")}
              sx={{ my: 2, color: "white", display: "block" }}
            >
              Marketplace Orders
            </Button>
            <Button
              onClick={() => navigate("/workflow-steps")}
              sx={{ my: 2, color: "white", display: "block" }}
            >
              Workflow Steps
            </Button>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings" onClick={handleOpenUserMenu}>
              {cognitoUserinfo ? (
                <Avatar
                  src={cognitoUserinfo.picture}
                  alt={cognitoUserinfo.name}
                />
              ) : (
                <AccountCircle fontSize="large" />
              )}
            </Tooltip>
            {cognitoUserinfo ? (
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem
                  key="name"
                  autoFocus={false}
                  onClick={() => {
                    navigate("./profile");
                    handleCloseUserMenu();
                  }}
                >
                  <Typography textAlign="center">
                    {cognitoUserinfo.name}
                  </Typography>
                </MenuItem>
                <MenuItem key="SignOut" onClick={() => Auth.signOut()}>
                  <Typography textAlign="center">Sign Out</Typography>
                </MenuItem>
              </Menu>
            ) : (
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem key="SignIn" onClick={() => Auth.federatedSignIn()}>
                  Sign In
                </MenuItem>
              </Menu>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default NavBar;
