import { Grid } from "@mui/material";
import { useState } from "react";

const EbayOauthConnection = (): JSX.Element => {
  const [displayMsg, setDisplayMsg] = useState(
    "Initializing Ebay Connection..."
  );
  return (
    <Grid container className="py-2">
      <Grid xs={8}>&nbsp;</Grid>
      <Grid xs={4}>{displayMsg}</Grid>
    </Grid>
  );
};

export default EbayOauthConnection;
