import { Grid } from "@mui/material";
import EbayOauthConnection from "./EbayOauthConnection";

const MarketplaceOrdersPage = (): JSX.Element => {
  return (
    <Grid container>
      <Grid xs={12}>
        <EbayOauthConnection />
      </Grid>
      <Grid xs={12}>
        <>MarketPlace Orders</>
      </Grid>
    </Grid>
  );
};

export default MarketplaceOrdersPage;
